import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
// import Login from '../Auth/Login';
// import SignUp from '../Auth/SignUp';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import completedArrowIcon from '../../assets/completedIcon.png'
import ListIconActive from '../../assets/listIconActive.png'
import ListIcon from '../../assets/listIcon.png'
import GroupIconDark from '../../assets/groupIconDark.png'
import DontAllowCheck from '../../assets/dontAllowCheck.png'
import { BASE_URL, decData, encData, post } from '../Services/Calls';
import AddProjectIcon from '../../assets/addProjectIcon.png'
import FolderIcon from '../../assets/folderIcon.png'
import AddGroupIcon from '../../assets/addGroupIcon.png'
import GroupIcon from '../../assets/groupIcon.png'


interface IProps {
    renameListForMobile:any
    setRenameListForMobile:any
    renameListInputValue:any
    setRenameListInputValue:any
    renameListForMobileFun:any
    dataItem:any
    renameListType:any
    addNewProjectForMobileFun:any
    renameGroupForMobileFun:any
    colorSchemes:any
    isDark:any
    mobileView:any
}


function RenameListMobile(
  {
    renameListForMobile,
    setRenameListForMobile,
    renameListInputValue,
    setRenameListInputValue,
    renameListForMobileFun,
    dataItem,
    renameListType,
    addNewProjectForMobileFun,
    renameGroupForMobileFun,
    isDark,
    colorSchemes, mobileView
  }: IProps
) {




  useEffect(() => {
    
  }, []);




  
  return (
    <div>
      <Dialog
        open={renameListForMobile}
        onClose={() => { setRenameListForMobile(false) }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ClickAwayListener onClickAway={() => { setRenameListForMobile(false) }}>
          <div className='switchDialogMainDiv' style={{ textAlign: 'center',background:colorSchemes.DrawerBackColor }}>
            <div style={{ textAlign: 'end', width: '100%' }}>
              <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setRenameListForMobile(false) }} src={CloseMenuListIcon} alt='' />
            </div>
            <div className="manageAccountContainer" style={{ marginTop: -24 }}>
              <h4 style={{color:colorSchemes.FontBlackColor }}>{renameListType=="Rename Project" ? "Rename Folder" : renameListType=="New Project"? "New Folder":renameListType}</h4>
              <div>
                <div className='shareDialogInputAndIcon' style={{ width: '100%' }}>
                <div style={{ width: '100%',display: 'flex',justifyContent: 'flex-start',alignItems: 'center',gap: 5,padding:mobileView?'13px 15px':'5px 7px',background:colorSchemes.MainBackGroundColor, borderRadius: "5px"}}>
                <img style={{width:16}} src={renameListType == "Rename List"?ListIcon:mobileView?GroupIconDark:GroupIcon} alt="" />
                <input
                  className='shareDialogInput'
                  style={{background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor}}
                  type='text'
                  name='name'
                  placeholder={renameListType == "Rename List" ? 'Type list name here' : 'Type folder name here'}
                  autoFocus={renameListType == "Rename List" ? true : false}
                  value={renameListInputValue}
                  onChange={(e)=>setRenameListInputValue(e.target.value)}
                //   onBlur={()=> {if(renameListType == "Rename List")
                //   {
                //     renameListForMobileFun()
                // }
                // else{
                //     addNewProjectForMobileFun()
                // }
                // }
                // }
                  // onKeyDown={handleKeyDownForProject}
                  autoComplete='off'
                />
              </div>
              </div>

              </div>

              <div className='shareDialogInviteBtn' style={{ display: 'flex', marginTop:mobileView?40:25, justifyContent: 'flex-end', gap: 0 }}>
                <button
                  style={{
                    background: colorSchemes.MainBackGroundColor,
                    color: colorSchemes.FontGreyColor,
                    border: '0.1px solid rgba(0, 0, 0, 0.3)',
                  }}
                  onClick={() => { setRenameListForMobile(false);}}
                >
                  Close
                </button>
                <button
                  style={{ background: 'rgb(71,114,250)', color: '#fff'/* ,opacity:projectsInputValue== "" ? 0.65 :1 */}}
                  onClick={()=>{ 
                    if(renameListType == "Rename List")
                  {
                    setRenameListForMobile(false);renameListForMobileFun()
                }
                else if(renameListType == "Rename Project")
                  {
                    setRenameListForMobile(false);renameGroupForMobileFun()
                }
                else{
                    setRenameListForMobile(false); addNewProjectForMobileFun()
                }
                    }}
                >
                  Save
                </button>

              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Dialog>
    </div>
  );
}

export default RenameListMobile;
