import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import Login from '../Auth/Login';
import SignUp from '../Auth/SignUp';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


interface IProps {
    openAddAccountLogin: any
    setOpenAddAccountLogin: any
    openAddAccountSignup: any
    setOpenAddAccountSignUp: any
    setOpenSwitchAccountDialog: any
    mobileView: any
    isDark: any
    colorSchemes: any
}


function AddAccount({ 
    openAddAccountLogin,
    setOpenAddAccountLogin,
    openAddAccountSignup,
    setOpenAddAccountSignUp,
    setOpenSwitchAccountDialog,
    mobileView,
    isDark,
    colorSchemes
    }: IProps) {


//   const [verificationStatus,setVerificationStatus] = useState("0");

  useEffect(() => {
    
  }, []);


  return (
      <div>
        {!mobileView ?
<Dialog
                    open={openAddAccountLogin}
                    onClose={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <ClickAwayListener onClickAway={()=>{setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}>
                    <div className='switchDialogMainDiv' style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'end', width: '100%' }}>
                        <img style={{ width: 16, cursor: 'pointer' }} onClick={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}} src={CloseMenuListIcon} alt='' />
                      </div>
                      {!openAddAccountSignup ?
                      <Login openAddAccountLogin={openAddAccountLogin} 
                             setOpenAddAccountLogin={setOpenAddAccountLogin} 
                             openAddAccountSignup={openAddAccountSignup} 
                             setOpenAddAccountSignUp={setOpenAddAccountSignUp} 
                             mobileView={mobileView}
                             />
                      :
                      <SignUp openAddAccountLogin={openAddAccountLogin} 
                              setOpenAddAccountLogin={setOpenAddAccountLogin} 
                              openAddAccountSignup={openAddAccountSignup} 
                              setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                              mobileView={mobileView}
                      />
                      }
                    </div>
                    </ClickAwayListener>
                  </Dialog>
                  :
                  <SwipeableDrawer
        anchor="bottom"
        open={openAddAccountLogin}
        onClose={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}
        onOpen={() => setOpenAddAccountLogin(true)}
        className='mainDrawerContainer'
      >
        <div style={{background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey}}>
        <div className='drawerIcon'>
          <div ></div>
        </div>
        <div className='drawerHeading'>
          <div style={{ width: '20%' }}></div>
          <div style={{ width: '100%', textAlign: 'center', fontSize: 18.5, fontWeight: '600' }}>
          Switch Accounts
          </div>
          <div className='drawerHeadingDone' onClick={()=>{setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}}>Done</div>
        </div>
                    <div className='switchDialogMainDiv' style={{ textAlign: 'center',background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey }}>
                    {/* <div style={{ textAlign: 'end', width: '100%' }}>
                        <img style={{ width: 16, cursor: 'pointer' }} onClick={() => {setOpenAddAccountLogin(false);setOpenSwitchAccountDialog(true);setOpenAddAccountSignUp(false)}} src={CloseMenuListIcon} alt='' />
                      </div> */}
                      {!openAddAccountSignup ?
                      <Login openAddAccountLogin={openAddAccountLogin} 
                             setOpenAddAccountLogin={setOpenAddAccountLogin} 
                             openAddAccountSignup={openAddAccountSignup} 
                             setOpenAddAccountSignUp={setOpenAddAccountSignUp} 
                             mobileView={mobileView}
                             />
                      :
                      <SignUp openAddAccountLogin={openAddAccountLogin} 
                              setOpenAddAccountLogin={setOpenAddAccountLogin} 
                              openAddAccountSignup={openAddAccountSignup} 
                              setOpenAddAccountSignUp={setOpenAddAccountSignUp}
                              mobileView={mobileView}
                      />
                      }
                    </div>
                    </div>
        </SwipeableDrawer>
    }
      </div>
    );
}

export default AddAccount;
