import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import HelpBgImg from '../../assets/helpBgImg.png'
import BlueBackArrow from '../../assets/blueBackArrow.png'
import RightBlackArrow from '../../assets/rightBlackArrow.png'
import RightWhiteArrow from '../../assets/rightWhiteArrow.png'
import { useNavigate } from 'react-router-dom';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

function HelpPage() {
    const navigate = useNavigate();
  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );

  const [backArrowChange, setBackArrowChange]: any = useState<boolean>(false);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768)
        setMobileView(true);
      else
        setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(() => {
    console.log('test454545')
    return () => {
        console.log('test4545451');
        tawkMessengerRef?.current?.minimize();
        console.log('test4545452');
    };
  }, []);


  const tawkMessengerRef: any = useRef()
  const onLoadChat = () => {
    tawkMessengerRef?.current?.hideWidget()
    //tawkMessengerRef.current.toggle();
  }



  return (
    <div>
     <TawkMessengerReact
        ref={tawkMessengerRef}
        propertyId='6469407274285f0ec46ca932'
        widgetId='1h0ti7fk6'
        onLoad={onLoadChat}
      />
        <div style={!mobileView?{display: 'flex',alignItems: 'center',gap: 30,position: 'absolute', top: 25,left: 25}:{margin:'20px 15px'}}>
            <div style={{fontSize:18,cursor:'pointer'}} onMouseEnter={()=>{if(!mobileView)setBackArrowChange(true)}} onMouseLeave={()=>{if(!mobileView)setBackArrowChange(false)}} onClick={()=>{tawkMessengerRef?.current?.minimize();navigate('/webapp')}}>
                {!backArrowChange ?
         <img src={mobileView?RightWhiteArrow:RightBlackArrow} style={{width:mobileView?15:22,transform:'rotate(180deg)',marginBottom:3}} alt="" />
         :
         <img src={BlueBackArrow} style={{width:12.2,marginBottom:3,marginLeft:6,marginRight:3}} alt="" />
        }
         
         <span style={{color:mobileView?'#fff':!backArrowChange?'':'#4772fa'}}>Back</span> 
         </div>
        <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0,color:'#4772fa',marginTop:mobileView?30:'' }}>ManageStuff</h2>
        </div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:mobileView?50:100,marginBottom:50}}>
    <img style={{width:mobileView?'80%':''}} src={HelpBgImg} alt="" />
</div>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'column',gap:25}}>
    <div style={{fontSize:26,fontWeight:'bold',textAlign:'center',color:mobileView?'#E4E4E4':''}}>
    Need help?
    </div>
    <div className='helpContactUsBtn' onClick={()=>tawkMessengerRef?.current?.toggle()}>
        Contact Us
    </div>
</div>
    </div>
  );
}

export default HelpPage;
