import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
/* import Login from '../Auth/Login';
import SignUp from '../Auth/SignUp'; */
import ClickAwayListener from '@mui/material/ClickAwayListener'
import completedArrowIcon from '../../assets/completedIcon.png'
import ListIconActive from '../../assets/listIconActive.png'
import ListIcon from '../../assets/listIcon.png'
import DontAllowCheck from '../../assets/dontAllowCheck.png'
import { BASE_URL, decData, encData, post } from '../Services/Calls';
import AddProjectIcon from '../../assets/addProjectIcon.png'
import FolderIcon from '../../assets/folderIcon.png'


interface IProps {
  addNewListDialog: any
  setAddNewListDialog: any
  groupData: any
  groupInputValue:any
  handleChangeForGroup:any
  handleKeyDownForGroup:any
  setGroupInputValue:any
  addGroups:any
  selectedFolderItem:any
  setSelectedFolderItem:any
  projectsInputValue:any
  handleChangeForProject:any
  handleKeyDownForProject:any
  addProjectToList:any
  setProjectsInputValue:any
  groupDataRef:any
  userId:any
  setGroupData:any
  getProjectWithGroup:any
  projectData:any
  userData:any
  colorSchemes:any
  isDark:any
  mobileView:any
  sendSocketMessage:any
}


function AddListDialog(
  {
    addNewListDialog,
    setAddNewListDialog,
    groupData,
    groupInputValue,
    handleChangeForGroup,
    handleKeyDownForGroup,
    setGroupInputValue,
    addGroups,
    selectedFolderItem,
    setSelectedFolderItem,
    projectsInputValue,
    handleChangeForProject,
    handleKeyDownForProject,
    addProjectToList,
    setProjectsInputValue,
    userId,
    groupDataRef,
    setGroupData,
    getProjectWithGroup,
    projectData,
    userData,
    colorSchemes,
    isDark,
    mobileView,
    sendSocketMessage
  }: IProps
) {


  const [newListType, setNewListType] = useState(false);
  const [selectFolder, setSelectFolder] = useState(false);
  const [listType, setListType] = useState("Task List");
  // const [selectedFolderItem, setSelectedFolderItem]:any = useState({});
  const [showAddNewFolderDiv, setShowAddNewFolderDiv] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const mySelectDiv:any = useRef(0);
  


  




  function selectListType() {
    if (newListType) {
      setNewListType(false)
    }
    else {
      setNewListType(true)
      setSelectFolder(false)
    }
  }

  function selectFolderType() {
    if (selectFolder) {
      setSelectFolder(false)
    }
    else {
      setSelectFolder(true)
      setNewListType(false)
    }
  }

  function selectListTypeValue(type) {
    if (type == "List") {
      setListType("Task List")
      setNewListType(false)
    }
    else {
      setListType("Note List")
      setNewListType(false)
    }
  }

  function selectFolderItem(item) {
    setSelectedFolderItem(item)
  }


  function openAddNewFloderDialog() {
    setShowAddNewFolderDiv(true)
  }

// function newFolderSelect(){
//   console.log('tempGroupData1')
//   setTimeout(() => {
//     console.log('tempGroupData2',groupData[groupData.length - 1])
//     let tempGroupData = groupData[groupData.length - 1];
//     selectedFolderItem(tempGroupData)
//     console.log('tempGroupData3',tempGroupData)
//   }, 100);
// }

async function addGroupsItem(){
  if(groupInputValue == ""){
    return;
  }
  setBtnLoader(true)
 await addGroups();
 setBtnLoader(false)
  setShowAddNewFolderDiv(false); 
  setGroupInputValue('');
  // newFolderSelect()
}


async function createList(){
  if(projectsInputValue== ""){
    return;
  }
  await addListIntoProject(selectedFolderItem)
  setProjectsInputValue('')

  setAddNewListDialog(false); 
  setListType("Task List"); 
  setNewListType(false); 
  setSelectFolder(false); 
  setSelectedFolderItem({})
}

async function addListIntoProject(selectedFolderItem) {
  let allgroupData = [...groupData]

  console.log('test1', allgroupData)
  let config = {
    userId: userId,
    projectName: encData(projectsInputValue),
    projectGroupId: selectedFolderItem && selectedFolderItem.groupName ? selectedFolderItem.id : 0 ,
    isNote : listType == "Task List" ? 0 : 1
  }
  console.log('test2', config)

  try {
    const apiData = await post(BASE_URL + '/addproject', config)
    if (apiData.data.status == 'success') {
      try
      {
        let json={projectId:apiData.data.data[0].id};
    sendSocketMessage("addproject", json)
      }
      catch(ex)
      {

      }
      console.log('apiData', apiData.data.data[0])

      for (let i = 0; i < allgroupData.length; i++) {
        if (allgroupData[i].id == selectedFolderItem.id) {
          allgroupData[i].open = true
          allgroupData[i].project.push(apiData.data.data[0])
          setGroupData(allgroupData)
          groupDataRef.current=allgroupData
        }
      }
      // getProjectWithGroup(userId, 0)
    }
  } catch (ex) {
    console.log('response9211', ex)
  }
}

  return (
    <div>
      <Dialog
        open={addNewListDialog}
        onClose={() => { setAddNewListDialog(false); setListType("Task List"); setNewListType(false); setSelectFolder(false); setSelectedFolderItem({});setProjectsInputValue('') }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <ClickAwayListener onClickAway={() => { setAddNewListDialog(false); setListType("Task List"); setNewListType(false); setSelectFolder(false); setSelectedFolderItem({});setProjectsInputValue('') }}>
          <div className='switchDialogMainDiv' style={{ textAlign: 'center',background:colorSchemes.DrawerBackColor }}>
            <div style={{ textAlign: 'end', width: '100%' }}>
              <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setAddNewListDialog(false); setListType("Task List"); setNewListType(false); setSelectFolder(false); setSelectedFolderItem({});setProjectsInputValue('') }} src={CloseMenuListIcon} alt='' />
            </div>
            <div className="manageAccountContainer" style={{ marginTop: -24 }}>
              <h4 style={{color:colorSchemes.FontBlackColor }}>Add List</h4>
              <div>
                <div className='shareDialogInputAndIcon' style={{ width: '100%' }}>
                <div style={{ width: '100%',display: 'flex',justifyContent: 'flex-start',alignItems: 'center',gap: 5,padding: mobileView? '13px 10px':'5px 6px',background:colorSchemes.MainBackGroundColor, borderRadius: "5px"}}>
                <img style={{width:16}} src={ListIcon} alt="" />
                <input
                  className='shareDialogInput'
                  style={{background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor}}
                  type='text'
                  name='name'
                  placeholder='Type list name here'
                  // autoFocus={true}
                  value={projectsInputValue}
                  onChange={handleChangeForProject}
                  // onKeyDown={handleKeyDownForProject}
                  autoComplete='off'
                />
              </div>
              </div>
                <div style={{ paddingTop: mobileView?30: 30, paddingBottom:mobileView?20:0, borderBottom: '1px solid lightgray' }}>
                  <div className='addNewListSelectContainer'>
                    <div style={{color:colorSchemes.FontBlackColor }}>Type</div>
                    <div className='listSelectTypeDiv' onClick={() => selectListType()} style={{color:colorSchemes.FontBlackColor,background:colorSchemes.MainBackGroundColor }}>
                      {listType}
                      <img style={{ width: 16 }} className='listSelectDownArrow' src={completedArrowIcon} alt="" />
                    </div>
                    {newListType &&
                      <ClickAwayListener onClickAway={() => { setNewListType(false) }}>
                        <div className='selectListTypeDropDown' style={{background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor,border:colorSchemes.Border,width:mySelectDiv.current.offsetWidth}}>
                          <div className={listType == "Task List" ? 'listTypeDropElement listTypeItemActive' : 'listTypeDropElement'} onClick={() => { selectListTypeValue("List") }}>
                            <span>
                              Task List
                            </span>
                            {listType == "Task List" ?
                              <img style={{ width: 15 }}
                                src={DontAllowCheck}
                                alt=''
                              />
                              : null}
                          </div>
                          <div className={listType == "Note List" ? 'listTypeDropElement listTypeItemActive' : 'listTypeDropElement'} onClick={() => { selectListTypeValue("Note") }}>
                            <span>
                              Note List
                            </span>
                            {listType == "Note List" ?
                              <img style={{ width: 15 }}
                                src={DontAllowCheck}
                                alt=''
                              />
                              : null}
                          </div>

                        </div>
                      </ClickAwayListener>
                    }
                  </div>
                  <div className='addNewListSelectContainer'>
                    <div style={{color:colorSchemes.FontBlackColor }}>Project</div>
                    <div className='listSelectTypeDiv' ref={mySelectDiv}  onClick={() => {selectFolderType();console.log('test 678',mySelectDiv.current.offsetWidth)} } style={{color:colorSchemes.FontBlackColor,background:colorSchemes.MainBackGroundColor }}>
                      {selectedFolderItem && selectedFolderItem.groupName ?
                        <span style={{color:colorSchemes.FontBlackColor }}>{decData(selectedFolderItem.groupName, selectedFolderItem.isEncrypt)}</span>
                        :
                        <span style={{color:colorSchemes.FontBlackColor }}>Choose</span>
                      }
                      <img style={{ width: 16 }} className='listSelectDownArrow' src={completedArrowIcon} alt="" />
                    </div>
                    {selectFolder &&
                      <ClickAwayListener onClickAway={() => { setSelectFolder(false) }}>
                        <div className='selectFolderTypeDropDown' style={{background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor,border:colorSchemes.Border,width:mySelectDiv.current.offsetWidth}}>
                          {projectData && projectData.length > 0 &&
                          <div className={selectedFolderItem && !selectedFolderItem.id ? 'listTypeDropElement listTypeItemActive' : 'listTypeDropElement'} onClick={() => { setSelectedFolderItem({});setSelectFolder(false) }}>
                            <span>Choose</span>
                            {selectedFolderItem && !selectedFolderItem.id ?
                              <img style={{ width: 15 }}
                                src={DontAllowCheck}
                                alt=''
                              />
                              : null}
                          </div>
                          }
                          {groupData && groupData.length > 0 && groupData.map((item: any, i: any) => (
                            <>
                            {userId == item.userId &&
                            <div className={selectedFolderItem && selectedFolderItem.id == item.id ? 'listTypeDropElement listTypeItemActive' : 'listTypeDropElement'} onClick={() => { selectFolderItem(item);setSelectFolder(false) }}>
                              <span>{decData(item.groupName, item.isEncrypt)}</span>
                              {selectedFolderItem && selectedFolderItem.id == item.id ?
                                <img style={{ width: 15 }}
                                  src={DontAllowCheck}
                                  alt=''
                                />
                                : null}
                            </div>
                            }
                            </>
                          ))}
                          <div className='listTypeDropElement' style={{ justifyContent: 'flex-start', gap: 10 }} onClick={() => { openAddNewFloderDialog();setSelectFolder(false) }}>
                            <img style={{ width: 15 }}
                              src={AddProjectIcon}
                              alt=''
                            />
                            <span>New Project</span>
                          </div>
                        </div>
                      </ClickAwayListener>
                    }
                  </div>
                </div>

              </div>

              <div className='shareDialogInviteBtn' style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end', gap: 0 }}>
                <button
                  style={{
                    background: colorSchemes.MainBackGroundColor,
                    color: colorSchemes.FontGreyColor,
                    border: '0.1px solid rgba(0, 0, 0, 0.3)',
                  }}
                  onClick={() => { setAddNewListDialog(false); setListType("Task List"); setNewListType(false); setSelectFolder(false); setSelectedFolderItem({});setProjectsInputValue('') }}
                >
                  Close
                </button>
                <button
                  style={{ background: projectsInputValue != "" && selectedFolderItem && selectedFolderItem.id ? 'rgb(71,114,250)' : 'rgba(181,199,253)', color: '#fff',opacity:projectsInputValue != "" && selectedFolderItem && selectedFolderItem.id ? 1 : 0.5}}
                  onClick={()=>{if(projectsInputValue != "" && selectedFolderItem && selectedFolderItem.id){createList()}}}
                >
                  Save
                </button>

              </div>
            </div>
            {showAddNewFolderDiv &&
              <div className='addNewFloderMainDiv'>
                <div className='addNewFloderContainer'>
                  <div className='addNewFloderInnerDiv' style={{background:colorSchemes.DrawerBackColor,border:colorSchemes.Border}}>

                    <div style={{ textAlign: 'end', width: '100%' }}>
                      <img style={{ width: 16, cursor: 'pointer' }} onClick={() => {setShowAddNewFolderDiv(false);setGroupInputValue('')}} src={CloseMenuListIcon} alt='' />
                    </div>
                    <div className="manageAccountContainer" style={{ marginTop: -24 }}>
                      <h5 style={{color:colorSchemes.FontBlackColor }}>New Project</h5>
                      <div className='shareDialogInputAndIcon'>
              <div style={{ width: '100%',display: 'flex',justifyContent: 'flex-start',alignItems: 'center',gap: 5,padding: '0px 7px',background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor}}>
                <img style={{width:18}} src={FolderIcon} alt="" />
                <input
                  className='shareDialogInput'
                  style={{background:colorSchemes.MainBackGroundColor,color:colorSchemes.FontBlackColor}}
                  type='text'
                  name='name'
                  placeholder='Name'
                  // autoFocus={true}
                  value={groupInputValue}
                  onChange={handleChangeForGroup}
                  // onKeyDown={handleKeyDownForGroup}
                  autoComplete='off'
                />
              </div>
            </div>
                    </div>
                    <div className='shareDialogInviteBtn' style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end', gap: 0 }}>
                <button
                  style={{
                    background: colorSchemes.MainBackGroundColor,
                    color: colorSchemes.FontGreyColor,
                    border: '0.1px solid rgba(0, 0, 0, 0.3)',
                  }}
                  onClick={() => {setShowAddNewFolderDiv(false);setGroupInputValue('')}}
                >
                  Close
                </button>
                {btnLoader ?
                <button
                  style={{ background: 'rgb(71,114,250)', color: '#fff', opacity: 0.65 }}
                >
                  Save...
                </button>
                :
                <button
                  style={{ background: 'rgb(71,114,250)', color: '#fff',opacity:groupInputValue== "" ? 0.65 :1}}
                  onClick={()=>{addGroupsItem()}}
                >
                  Save
                </button>
}

              </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </ClickAwayListener>
      </Dialog>
    </div>
  );
}

export default AddListDialog;
