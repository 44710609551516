import React,{ useState }  from 'react'
import styles from './AuthStyles'
import MainLogo from '../../assets/mainLogo1.png'
import LeftArrow from '../../assets/left-arrow.png'
import AuthLoader from '../../assets/authLoader.png' 
import { BASE_URL, post } from '../Services/Calls'
import RegisterSuccessIcon from '../../assets/registerSuccess.png' 
import ExpiredIcon from '../../assets/expiredIcon.png' 
import { Base64 } from 'js-base64';
import { useEffect } from 'react'
import Padlock from '../../assets/padlock.png' 
import PadlockActive from '../../assets/padlockActive.png' 
import { useNavigate } from 'react-router-dom';
import { COLOR, COLORDARK } from '../Services/constants/Colors'

interface IProps {
    resetPasswordStatus:any,
    code:any,
  }

function ResetPassword ({resetPasswordStatus,code}: IProps) {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordMsg, setPasswordMsg] = useState<string>("");
  const [confirmPasswordMsg, setConfirmPasswordMsg] = useState<string>("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [emailMsg, setEmailMsg] = useState<string>("");
  const [passwordInputFocus, setPasswordInputFocus] = useState(false);
  const [confirmPasswordInputFocus, setConfirmPasswordInputFocus] = useState(false);
  const [queryCode, setQueryCode] = useState("");

  const [mobileView, setMobileView]: any = useState<boolean>(
    window.innerWidth <= 768
  );
  const [isDark, setIsDark]: any = useState(mobileView ? 1 : 0);
const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;


useEffect(() => {
  function updateSize() {
    if (window.innerWidth <= 768)
      setMobileView(true);
    else
      setMobileView(false);
  }
  window.addEventListener("resize", updateSize);
}, [mobileView]);

  // const [resetPasswordStatus,setResetPasswordStatus] = useState("0");
  //   const [queryForRegister, setQueryForRegister] = useState<string | null>("");
  
    useEffect(() => {
      var myCookie = localStorage.getItem("setCookie");
      var Intro = localStorage.getItem("intro");
      localStorage.clear();
      if (myCookie != null) {
        localStorage.setItem("setCookie", myCookie);
      }
      if (Intro != null) {
      localStorage.setItem("intro", Intro);
      }
    }, []);
  
  
    // async function verifyLink(code) {
    //   try {
    //     const data = {
    //       code:code,
    //     };
    //   //   setLoader(true)
    //     const response = await post(BASE_URL + '/verifylink', data)
    //     console.log('response23',response)
    //     if(response.data.status == 'success'){
    //       // setLoader(false)
    //       setResetPasswordStatus("1")
    //     }
    //     else{
    //       // setLoader(false)
    //       setResetPasswordStatus("2")
    //     }
  
    //   } catch (ex) {
    //     console.log('response',ex)
    //   //   setLoader(false)
    //   setResetPasswordStatus("2")
    //   }
    // };



const handleResetPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    const form_data:any = e.currentTarget.elements;
    
    try {


      if (form_data[0].value == "") {
        setPasswordMsg("Password can't be empty");
      }
      if (form_data[0].value != "") {
        if(form_data[0].value.length < 6){
        setPasswordMsg("Password requires 6~64 characters long.");
        return;
      }
    }
      if (form_data[1].value == "") {
        setConfirmPasswordMsg("Confirm password can't be empty");
      }
      if (form_data[1].value != "") {
        if(form_data[1].value.length < 6){
          setConfirmPasswordMsg("Confirm password requires 6~64 characters long.");
        return;
      }
    }
          if (
            form_data[0].value == "" ||
            form_data[1].value == "" 
          ) {
            return;
          }
          if (form_data[0].value !== form_data[1].value) {
            setConfirmPasswordMsg("Passwords do not match");
            return;
          }
      const data = {
        code:code,
        password:Base64.encode(form_data[0].value)
      };
      console.log("data123456", data);
      setLoader(true)
      const response = await post(BASE_URL + '/resetpassword', data)
      console.log('response23',response)
      if(response.data.status == 'success'){
        setLoader(false)
        setResetSuccess(true)
      }
      else{
        setLoader(false)
      }

    } catch (ex) {
      console.log('response',ex)
      setLoader(false)
    }
  };

  useEffect(() => {
    console.log('resetPasswordStatus456',resetPasswordStatus)
  }, []);

  return (
    <div className='container-main'>
      <h2 style={{textAlign:'center',fontWeight:'bold',marginBottom:36,color:colorSchemes.FontBlackColor}}>ManageStuff</h2>
     <div className='auth-container-box' style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor, border:"1px solid "+colorSchemes.FontGreyColor}:{}}>
    <div>
       {resetPasswordStatus &&  resetPasswordStatus =="1" ? <>
           {!resetSuccess ?
       <div>
        <form onSubmit={handleResetPasswordSubmit}>

        <div className={passwordInputFocus?'inputContainerDivActive':'inputContainerDiv'}
        style={isDark==1 && passwordInputFocus? {borderBottom:"2px solid #4772fa"}: isDark==1?{borderBottom:"2px solid "+colorSchemes.FontGreyColor}:{}}
        >
           {passwordInputFocus?
<img style={{width:20}} src={PadlockActive} alt="" />
:
<img style={{width:20}} src={Padlock} alt="" />
}
<div style={{width:'100%'}}>
<input className='inputFeild' type="password" name="password" placeholder="Password"  maxLength={64} onFocus={() => {setPasswordMsg("");setPasswordInputFocus(true);}} onBlur={(e)=>{setPasswordInputFocus(false);
  if (e.target.value != "") {
        if(e.target.value.length < 6){
        setPasswordMsg("Password requires 6~64 characters long.");
      }}
}}
style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor}:{}}
/>
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}><span style={styles.inputErrorMsg}>{passwordMsg}</span></div>

        <div className={confirmPasswordInputFocus?'inputContainerDivActive':'inputContainerDiv'} 
        style={isDark==1 && confirmPasswordInputFocus? {borderBottom:"2px solid #4772fa"}: isDark==1?{borderBottom:"2px solid "+colorSchemes.FontGreyColor}:{}}
        >
           {confirmPasswordInputFocus?
<img style={{width:20}} src={PadlockActive} alt="" />
:
<img style={{width:20}} src={Padlock} alt="" />
}
<div style={{width:'100%'}}>
<input className='inputFeild' type="password" name="password" placeholder="Confirm Password"  maxLength={64} onFocus={() => {setConfirmPasswordMsg("");setConfirmPasswordInputFocus(true);}} onBlur={(e)=>{setConfirmPasswordInputFocus(false);
  if (e.target.value != "") {
        if(e.target.value.length < 6){
          setConfirmPasswordMsg("Confirm password requires 6~64 characters long.");
      }}
}}
style={isDark==1? {backgroundColor:colorSchemes.DrawerBackColor, color:colorSchemes.FontBlackColor}:{}}
/>
</div>
           </div>
           <div className='errorMsgLine' style={{minHeight:30}}><span style={styles.inputErrorMsg}>{confirmPasswordMsg}</span></div>

        {loader ?
    <button className='authBtnLoader' style={{marginTop:5}}>
    Reset Password...
    </button>
  :
        <button className='authBtn' type="submit" style={{marginTop:5}}>
       Reset Password
        </button>
}
</form>

<div style={{textAlign:'center',marginTop:20,fontSize:14}}>
  <span className='makeLink' onClick={() => navigate("/login")} /* onClick={()=>window.open('https://www.managestuff.com/', "_self") } */>Back to Sign In</span>
</div>
       </div>
       :
       <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',gap:50}}>
  <img style={{width:80}} src={RegisterSuccessIcon} alt="" />
  <h5 style={{textAlign:'center'}}>Password change Successfully.</h5>
  <button  className='authBtn' onClick={() => navigate("/login")} /* onClick={()=>window.open('https://www.managestuff.com/', "_self") } */>
       Go To Login
        </button>
        </div>
}
</>
:
resetPasswordStatus &&  resetPasswordStatus=="2"?
<div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',gap:50}}>
<img style={{width:80}} src={ExpiredIcon} alt="" />
<h5 style={{textAlign:'center'}}>Your reset password link has been expired.</h5>
<button  className='authBtn' onClick={() => navigate("/login")} /* onClick={()=>window.open('https://www.managestuff.com/', "_self") } */>
       Go To Login
        </button>
      </div>:null
}   
    </div>
    </div>
    </div>

  )
}

export default ResetPassword
