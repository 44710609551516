import { COLOR } from '../Services/constants/Colors'
import BackGroungBlack from '../../assets/BackGroungBlack1.jpeg'

const styles = {
    mainNavDiv:{
        // width:'100%',
        // height:65,
        // background:'#E9E4E6',
        background:'#6478C7',
        // padding:10,
        // paddingLeft:15,
        paddingRight:20,
        display:'flex',
        alignItems:'center',
        margin:0,
        height: '6.5vh',
    },
    dotMenu:{
        // padding:10,
        cursor:'pointer',
        // '&::hover':{
        //     background: 'red',
        //   }
    },
    dotMenuName:{
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        height:'inherit',
    },
    searchBarDiv:{
        padding:10,
        paddingTop:5,
        paddingBottom:5,
        background:'#fff',
        width:500,
        // height:40,

    },
    searchBarMainDiv:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    manageStuffHeading:{
        paddingTop:10,
        paddingBottom:20,
        // color:COLOR.WhiteColor,
        // color:'#000',
        color:'#ffffff',
        fontSize:22,
        fontWeight:'bold',
        // marginLeft:20,
        marginRight:20
    },
    manageStuffHeadingMobile:{
        paddingTop:10,
        paddingBottom:20,
        color:'#6477C6',
        fontSize:22,
        fontWeight:'bold',
    },
    searchBarInput:{
        width: '90%',
       border:'none',
       fontSize:14,
    },
    searchBarIcon:{
        width: 18,
        cursor: 'pointer',
        transform: 'scaleX(-1)',
    },
    mainMenuDiv:{
        width: '100%',
        height:'100vh',
        padding:8,
        paddingTop:0,
        paddingLeft:0,
                // backgroundColor:COLOR.PageBackGroundColor,
        // backgroundColor:'#fff',
        // borderRadius:5,
        boxShadow:'0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1)',
    },
    SideBarDiv:{
        width: '100%',
        // height:'93.5vh',
        height: 'calc(100vh - 15px)',
        paddingTop:15,
        marginLeft:2,
        // backgroundColor:COLOR.PageBackGroundColor,
         backgroundColor:'#fff',
        //  backgroundColor:'#172D46',
        borderRadius:5,
        boxShadow:'0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1)',
    },
    SideBarDivMobile:{
        width: '100%',
        // height:'93.5vh',
        height: '100%',
        // paddingTop:15,
        // marginLeft:2,
        // backgroundColor:COLOR.PageBackGroundColor,
        //  backgroundColor:'#fff',
        // borderRadius:5,
        boxShadow:'0px 0.3px 0.9px rgba(0,0,0,0.1), 0px 1.6px 3.6px rgba(0,0,0,0.1)',
    },
    menuItems:{
        width: '100%',
        paddingTop:8,
        paddingBottom:8,
        paddingLeft:10,
        paddigRight:10,
        backgroundColor:'#fff',
        borderRadius:5,
        boxShadow:'0px 0.3px 2.5px rgba(0, 0, 0, 0.2),  0px 1.6px 3.6px rgba(0, 0, 0, 0.19)',
        marginBottom:15,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        cursor:'pointer',
    },
    menuItemsActive:{
        width: '100%',
        paddingTop:8,
        paddingBottom:8,
        paddingLeft:10,
        paddigRight:10,
        backgroundColor:'rgba(94, 151, 195, 0.6)',
        color:'white',
        borderRadius:5,
        boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginBottom:15,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        cursor:'pointer',
    },
    
}

export default styles
