export const COLOR = {
   MainBackGroundColor:'#fff',
   FontGreyColor:'#212529',
   FontBlackColor:'#000',
   FontSemiGrey:'#212529',
   ActiveBlack:'#ECF1FF',
   SearchBackColor:'#fff',
   DrawerBackColor:'#fff',
   FontLightBlack: 'rgba(25,25,25,0.6)',
   ManageAccountInputBack: '#fff',
   Border: '1px solid #fff',
   MiddlePaneBackground: '#6478c7',
   MiddlePaneBlurBackground: 'rgb(100, 120, 199,0.6)',
   AddTaskBackground: '#5466ad',
   NotesBackColor:'#F6F7E6',
   taskActive:'#dadce6',
   ListProject:'#212529',
   ListFontColor:'#212529',
   WebFontSemiGrey:'#D6DFEA',   /* #212529 */
   WebFontWhite:'#FFFFFF',
   WebLeftPaneBg:'#172D46',    /* #fff */
   WebMiddlePaneBg:'#223853',   /* #6478c7 */
   WebMiddlePaneBlurBg:'rgba(34, 56, 83,0.6)',   /* rgb(100, 120, 199,0.6) */
   WebRightPaneBg:'#172D46',   /* '#fff' */
   WebFontBlackcolo:'#ffffff',  /* #000 */
   WebFontGreyColor:'#D6DFEA',  /* #212529 */ 
   WebListProject:'#ffffff',     /* #212529 */
   WebSearchBackColor:'#223853',     /* #fff*/
   WebActiveBlack:'#3A5371',    /* #ECF1FF */
   WebListFontColor:'#D6DFEA',  /* #212529 */
   WebRightInnerDivBg:'#223853',  /* #fff */
   WebNotesBackColor:'#223853',  /* #F6F7E6 */
   WebTaskBg:'#fff' /* #fff */
  };

  export const COLORDARK = {
   MainBackGroundColor:'#000',
   FontGreyColor:'#969696',
   // FontBlackColor:'#E4E4E4',
   FontBlackColor:'#E1E1E1',
   FontSemiGrey:'#E4E4E4',
   ActiveBlack:'#212121',
   SearchBackColor:'#212121',
   DrawerBackColor:'#212121',
   FontLightBlack: '#969696',
   ManageAccountInputBack: '#000',
   Border: '1px solid #fff',
   MiddlePaneBackground: '#000',
   MiddlePaneBlurBackground: 'rgb(0, 0, 0)',
   AddTaskBackground: '#212121',
   NotesBackColor:'#000',
   taskActive:'rgb(33, 33, 33)',
   ListProject:'rgba(228, 228, 228,0.6)',
   ListFontColor:'rgba(228, 228, 228,0.9)',
   WebFontSemiGrey:'#E4E4E4',
   WebFontWhite:'#E4E4E4',
   WebLeftPaneBg:'#172D46',  /* #000 */
   WebMiddlePaneBg:'#223853', /* #000 */
   WebMiddlePaneBlurBg:'rgb(34, 56, 83)', /* rgb(0, 0, 0) */
   WebRightPaneBg:'#212121',
   WebFontBlackcolo:'#E1E1E1',
   WebFontGreyColor:'#969696', 
   WebListProject:'rgba(228, 228, 228,0.9)',
   WebSearchBackColor:'#212121',
   WebActiveBlack:'#3A5371',
   WebListFontColor:'rgba(228, 228, 228,0.9)',
   WebRightInnerDivBg:'#212121', 
   WebNotesBackColor:'#000',  
   WebTaskBg:'#212121', 
  };