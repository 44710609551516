import React, { useEffect, useRef, useState } from 'react';
import './ManageAccount.css'
import { BrowserRouter, Routes, Route, redirect, Navigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog'
import { Base64 } from 'js-base64'
import CloseMenuListIcon from '../../assets/closeMenuListIcon.png'
import ManageCheckIcon from '../../assets/manageCheckIcon.png'
import { BASE_URL, post } from '../Services/Calls';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


interface IProps {
    openManageAccountDialog: any
    setOpenManageAccountDialog: any
    userData: any
    setMultiSnackbarMsg: any
    handleClickMultiSnackBar: any
    SlideTransition: any
    setUserData:any
    setEmailLandingPage:any
    mobileView:any
    colorSchemes:any
    isDark:any
    setFirstName:any
    setLastName:any
    setSwitchUserData:any
    sendSocketMessage:any
}


function ManageAccount({
    openManageAccountDialog,
    setOpenManageAccountDialog,
    userData,
    setMultiSnackbarMsg,
    handleClickMultiSnackBar,
    SlideTransition,
    setUserData,
    setEmailLandingPage,
    mobileView,
    colorSchemes,
    isDark,
    setFirstName,
    setLastName,
    setSwitchUserData,
    sendSocketMessage
}: IProps) {


    const [showChangePasswordDiv, setShowChangePasswordDiv] = useState(false);
    const [showChangeEmailDiv, setShowChangeEmailDiv] = useState(false);
    const [showChangeNameDiv, setShowChangeNameDiv] = useState(false);
    const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [email, setEmail] = useState("");
    const [newName, setNewName] = useState("");
    const [code, setCode] = useState("");
    const [changeEmailCurrentPassword, setChangeEmailCurrentPassword] = useState("");
    const [deleteAccountPassword, setDeleteAccountPassword] = useState("");
    const [changePasswordLoader, setChangePasswordLoader] = useState(false);
    const [changeDialog, setChangeDialog] = useState(false);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);

    useEffect(() => {

    }, []);


    function checkPoints(type) {
        if (type == "point1") {
            if (check1) {
                setCheck1(false)
            }
            else {
                setCheck1(true)
            }
        }
        else {
            if (check2) {
                setCheck2(false)
            }
            else {
                setCheck2(true)
            }
        }
    }


    function clearChangePasswordInput() {
        setCurrentPassword("")
        setNewPassword("")
        setNewName("")
    }
    function clearChangeEmailInput() {
        setEmail("")
        setCode("")
        setChangeEmailCurrentPassword("")
        setNewName("")
    }


    const handleChangePasswordSubmit = async () => {

        if (currentPassword && currentPassword.length < 6) {
            setErrorMsg("Password requires 6~64 characters long.");
            return;
        }
        if (newPassword && newPassword.length < 6) {
            setErrorMsg("Password requires 6~64 characters long.");
            return;
        }
        let config = {
            currentPassword: Base64.encode(currentPassword),
            password: Base64.encode(newPassword)
        }
        try {
            setChangePasswordLoader(true)
            const apiData = await post(BASE_URL + '/changepassword', config)
            console.log('apiData12', apiData)
            if (apiData && apiData.data && apiData.data.status == "success") {
                try
                {
                    let json={};
                    sendSocketMessage("updateprofile", json)
                }
                catch(ex)
                {

                }
                
                setChangePasswordLoader(false)
                setCurrentPassword("")
                setNewPassword("")
                setShowChangePasswordDiv(false)
                setMultiSnackbarMsg('Password change successfully')
                handleClickMultiSnackBar(SlideTransition)
            }
            else {
                setChangePasswordLoader(false)
                setErrorMsg(apiData.data.message)
            }
        }
        catch (ex) {
            setChangePasswordLoader(false)
        }

    }

    const handleSendCode = async () => {
        if (email && email != "") {
            let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if (!pattern.test(email)) {
                setErrorMsg("Invalid email format");
                return;
            }
        }
        let config = {
            email: Base64.encode(email),
        }
        let json = {
            email: Base64.encode(email),
            userId: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName
        }
        try {
            setChangePasswordLoader(true)
            const response = await post(BASE_URL + '/checkemailexist', config)
            console.log('apiData12', response)
            if(response && response.data && response.data.status && response.data.status == "success"){
                setChangePasswordLoader(false)
                setMultiSnackbarMsg('Code has been sent on your email.')
                handleClickMultiSnackBar(SlideTransition)
            const response1 = await post(BASE_URL + '/sendcodechangeemail', json)
            console.log('apiData123', response1)
            }
            else{
            setChangePasswordLoader(false)
            setErrorMsg(response.data.message)
            }
        }
        catch (ex) {
            setChangePasswordLoader(false)
        }

    }

    const handleChangeEmail = async () => {

        if (email && email != "") {
            let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if (!pattern.test(email)) {
                setErrorMsg("Invalid email format");
                return;
            }
        }
        let json = {
            email: Base64.encode(email),
            emailCode: code,
            currentPassword: Base64.encode(changeEmailCurrentPassword),
        }
        try {
            setChangePasswordLoader(true)
            const response = await post(BASE_URL + '/updateemail', json)
            console.log('apiData12345', response)
            if(response && response.data && response.data.status && response.data.status == "success"){
                try
                {
                    let json={};
                    sendSocketMessage("updateprofile", json)
                }
                catch(ex)
                {

                }
                setChangePasswordLoader(false)
                setMultiSnackbarMsg('Email Change successfully.')
                handleClickMultiSnackBar(SlideTransition)
                clearChangeEmailInput()
                setShowChangeEmailDiv(false)
                localStorage.setItem("user", JSON.stringify(response.data.data[0]));
                let tempGetSwitchData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
                
            let switchUserData = [...tempGetSwitchData]
            for (let i = 0; i < switchUserData.length; i++) {
              if (switchUserData[i].userData.id == response.data.data[0].id) {
                switchUserData[i].userData = response.data.data[0]
                setUserData(response.data.data[0]);
                setEmailLandingPage(Base64.decode(response.data.data[0].email))
                break;
              }
            }
            localStorage.setItem("switchUserData", JSON.stringify(switchUserData))
            setSwitchUserData(switchUserData)
            }
            else{
            setChangePasswordLoader(false)
            setErrorMsg(response.data.message)
            }
        }
        catch (ex) {
            setChangePasswordLoader(false)
        }

    }

    const handleChangeName = async () => {

        let splitName = newName.split(' ')
        console.log('splitName',splitName)
        let getLastname = ""
        if(splitName && splitName.length>1){
          getLastname=splitName[1]
        }

        let json = {
            firstName: Base64.encode(splitName[0]),
            lastName: Base64.encode(getLastname),
        }
        try {
            setChangePasswordLoader(true)
            const response = await post(BASE_URL + '/updatetaskusername', json)
            console.log('apiData1234567', response)
            if(response && response.data && response.data.status && response.data.status == "success"){
                try
                {
                    let json={};
                    sendSocketMessage("updateprofile", json)
                }
                catch(ex)
                {

                }
                setChangePasswordLoader(false)
                setMultiSnackbarMsg('Name Change successfully.')
                handleClickMultiSnackBar(SlideTransition)
                clearChangeEmailInput()
                setShowChangeNameDiv(false)
                localStorage.setItem("user", JSON.stringify(response.data.data[0]));
                let tempGetSwitchData = JSON.parse(localStorage.getItem('switchUserData') || '[]')
                
            let switchUserData = [...tempGetSwitchData]
            console.log("tempGetSwitchData45", tempGetSwitchData)
            for (let i = 0; i < switchUserData.length; i++) {
              if (switchUserData[i].userData.id == response.data.data[0].id) {
                switchUserData[i].userData = response.data.data[0]
                setUserData(response.data.data[0]);
                setFirstName(Base64.decode(response.data.data[0].firstName))
                setLastName(Base64.decode(response.data.data[0].lastName))
                break;
              }
            }
            localStorage.setItem("switchUserData", JSON.stringify(switchUserData))
            setSwitchUserData(switchUserData)
            }
            else{
            setChangePasswordLoader(false)
            setErrorMsg(response.data.message)
            }
        }
        catch (ex) {
            setChangePasswordLoader(false)
        }

    }

    const handleDeleteAccount = async () => {

        if (deleteAccountPassword && deleteAccountPassword.length < 6) {
            setErrorMsg("Password requires 6~64 characters long.");
            return;
        }
        let config = {
            userId: userData.id,
            currentPassword: Base64.encode(deleteAccountPassword)
        }
        try {
            setChangePasswordLoader(true)
            const apiData = await post(BASE_URL + '/deleteuseraccountbyuser', config)
            console.log('apiData12', apiData)
            if (apiData && apiData.data && apiData.data.status == "success") {
                var myCookie = localStorage.getItem("setCookie");
                var Intro = localStorage.getItem("intro");
                localStorage.clear();
                if (myCookie != null) {
                  localStorage.setItem("setCookie", myCookie);
                }
                if (Intro != null) {
                localStorage.setItem("intro", Intro);
                }
                window.location.reload()
                // setChangePasswordLoader(false)
                // setCurrentPassword("")
                // setNewPassword("")
                // setShowChangePasswordDiv(false)
                // setMultiSnackbarMsg('Password change successfully')
                // handleClickMultiSnackBar(SlideTransition)
            }
            else {
                setChangePasswordLoader(false)
                setErrorMsg(apiData.data.message)
            }
        }
        catch (ex) {
            setChangePasswordLoader(false)
        }

    }

    return (
        <div>
            {!mobileView ?
 <>
            {!changeDialog ?
                <Dialog
                    open={openManageAccountDialog}
                    onClose={() => { setOpenManageAccountDialog(false) }}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <ClickAwayListener onClickAway={()=>{setOpenManageAccountDialog(false)}}>
                    <div className='switchDialogMainDiv' style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'end', width: '100%' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setOpenManageAccountDialog(false) }} src={CloseMenuListIcon} alt='' />
                        </div>
                        <div className="manageAccountContainer">
                            <h3>Account Information</h3>

                            <div style={{ marginTop: 20 }}>
                                <div style={{ fontSize: 16, fontWeight: 600 }}>Password</div>
                                {!showChangePasswordDiv ?
                                    <div style={{ fontSize: 14, color: 'rgba(25,25,25,0.6)', marginTop: 4 }}>
                                        Already set &nbsp;<span className="makeLink" style={{ fontSize: 13 }} onClick={() => { setShowChangePasswordDiv(true); setShowChangeEmailDiv(false); setErrorMsg(""); clearChangeEmailInput();setShowChangeNameDiv(false); }}>Change Password</span>
                                    </div>
                                    :
                                    <div className="manageAccountInnerContentDiv" style={{ marginTop: 4 }}>
                                        <input className="manageAccountInput" type='password' placeholder="Current Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        <input className="manageAccountInput" type='password' placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 12, marginTop: -5 }}>{errorMsg}</div>
                                        }
                                        <div className="manageBtnContainer">
                                            <div className="manageCancleBtn" onClick={() => { setShowChangePasswordDiv(false);clearChangeEmailInput();clearChangePasswordInput() }}>Cancle</div>
                                            {currentPassword && currentPassword != "" && newPassword && newPassword != "" ?
                                                <>
                                                    {!changePasswordLoader ?
                                                        <div className="manageSaveBtnActive" onClick={() => handleChangePasswordSubmit()}>Save</div>
                                                        :
                                                        <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Save...</div>
                                                    }
                                                </>
                                                :
                                                <div className="manageSaveBtn">Save</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <div style={{ fontSize: 16, fontWeight: 600 }}>Name</div>
                                {!showChangeNameDiv ?
                                    <div style={{ fontSize: 14, color: 'rgba(25,25,25,0.6)', marginTop: 4 }}>
                                        {Base64.decode(userData.firstName) + ' ' + Base64.decode(userData.lastName)} &nbsp;
                                        <span className="makeLink" style={{ fontSize: 13 }} onClick={() => { setShowChangeNameDiv(true); setShowChangePasswordDiv(false);setShowChangeEmailDiv(false); setErrorMsg(""); clearChangePasswordInput()
                                    setNewName(Base64.decode(userData.firstName) + ' ' + Base64.decode(userData.lastName))
                                    }}>Change Name</span>
                                    </div>
                                    :
                                    <div className="manageAccountInnerContentDiv" style={{ marginTop: 4 }}>
                                        <input className="manageAccountInput" type='text' placeholder="New Name" value={newName} onChange={(e) => setNewName(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 12, marginTop: -5 }}>{errorMsg}</div>
                                        }
                                        <div className="manageBtnContainer">
                                            <div className="manageCancleBtn" onClick={() => { setShowChangeNameDiv(false);clearChangeEmailInput();clearChangePasswordInput() }}>Cancle</div>
                                            {newName && newName != "" ?
                                             <>
                                             {!changePasswordLoader ?
                                                <div className="manageSaveBtnActive" onClick={() => handleChangeName()}>Ok</div>
                                                :
                                                <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Ok...</div>
                                            }
                                                </>
                                                
                                                :
                                                <div className="manageSaveBtn">Ok</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>


                            <div style={{ marginTop: 20 }}>
                                <div style={{ fontSize: 16, fontWeight: 600 }}>Email</div>
                                {!showChangeEmailDiv ?
                                    <div style={{ fontSize: 14, color: 'rgba(25,25,25,0.6)', marginTop: 4 }}>
                                        {Base64.decode(userData.email)} &nbsp;
                                        <span className="makeLink" style={{ fontSize: 13 }} onClick={() => { setShowChangeEmailDiv(true); setShowChangePasswordDiv(false); setErrorMsg(""); clearChangePasswordInput();setShowChangeNameDiv(false); }}>Change Email</span>
                                    </div>
                                    :
                                    <div className="manageAccountInnerContentDiv" style={{ marginTop: 4 }}>
                                        <input className="manageAccountInput" type='email' placeholder="New Email" value={email} onChange={(e) => setEmail(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        <div style={{ display: 'flex' }}>
                                            <input className="manageAccountInput" style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, width: '100%' }} type='number' placeholder="Verification Code" value={code} onChange={(e) => setCode(e.target.value)} onFocus={() => setErrorMsg("")} />
                                            {email && email != "" ?
                                            <>{!changePasswordLoader ?
                                                <div className="sendCodeBtnActive" onClick={() => handleSendCode()}>Send Code</div>
                                                :
                                                <div className="sendCodeBtnActive" style={{ opacity: 0.65 }}>Send Code...</div>
                                            }
                                                </>
                                                :
                                                <div className="sendCodeBtn">Send Code</div>
                                            }
                                        </div>
                                        <input className="manageAccountInput" type='password' placeholder="Current Password" value={changeEmailCurrentPassword} onChange={(e) => setChangeEmailCurrentPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 12, marginTop: -5 }}>{errorMsg}</div>
                                        }
                                        <div className="manageBtnContainer">
                                            <div className="manageCancleBtn" onClick={() => { setShowChangeEmailDiv(false);clearChangeEmailInput();clearChangePasswordInput() }}>Cancle</div>
                                            {email && email != "" && code && code != "" && changeEmailCurrentPassword && changeEmailCurrentPassword != "" ?
                                             <>
                                             {!changePasswordLoader ?
                                                <div className="manageSaveBtnActive" onClick={() => handleChangeEmail()}>Ok</div>
                                                :
                                                <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Ok...</div>
                                            }
                                                </>
                                                
                                                :
                                                <div className="manageSaveBtn">Ok</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <div style={{ fontSize: 16, fontWeight: 600 }}>Manage Account</div>
                                <div style={{ fontSize: 14, color: 'rgba(25,25,25,0.6)', marginTop: 4 }}><span style={{ color: 'red', cursor: 'pointer' }} onClick={() => { setShowDeleteAccountDialog(true); setChangeDialog(true);setShowChangePasswordDiv(false); setShowChangeEmailDiv(false);clearChangeEmailInput();clearChangePasswordInput();setShowChangeNameDiv(false); }}>Delete Account</span></div>
                            </div>

                        </div>
                    </div>
                    </ClickAwayListener>
                </Dialog>

                :
                <Dialog
                    open={showDeleteAccountDialog}
                    onClose={() => { setShowDeleteAccountDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <ClickAwayListener onClickAway={()=>{ setShowDeleteAccountDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}>
                    <div className='switchDialogMainDiv' style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'end', width: '100%' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setShowDeleteAccountDialog(false); setChangeDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }} src={CloseMenuListIcon} alt='' />
                        </div>
                        <div className="manageAccountContainer">
                            <h3>Delete Account</h3>

                            <div style={{ marginTop: 20 }}>
                                <div style={{ color: 'rgba(255,49,128,1)', fontSize: 13, marginBottom: 20 }}>Warning: Deleting account will remove all your data!</div>
                                <input className="manageAccountInput" style={{ width: '100%' }} type='password' placeholder="Please confirm the current password." value={deleteAccountPassword} onChange={(e) => setDeleteAccountPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 14, }}>{errorMsg}</div>
                                        }
                                <div className="pointCheckBoxDiv" onClick={() => checkPoints("point1")}>
                                    {check1 ?
                                        <img className='checkIconDiv' src={ManageCheckIcon} alt="" />
                                        :
                                        <div className="unCheckDiv"></div>
                                    }
                                    <div>I am aware that deleting account will remove all my data.</div>
                                </div>
                                <div className="pointCheckBoxDiv" style={{ marginTop: 10 }} onClick={() => checkPoints("point2")}>
                                    {check2 ?
                                        <img className='checkIconDiv' src={ManageCheckIcon} alt="" />
                                        :
                                        <div className="unCheckDiv"></div>
                                    }
                                    <div>I am sure I want to delete my account.</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="manageBtnContainer" style={{ width: 245, marginTop: 60, marginBottom: 10 }}>
                                        <div className="manageCancleBtn" onClick={() => { setShowDeleteAccountDialog(false); setChangeDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}>Cancle</div>
                                        {deleteAccountPassword && deleteAccountPassword.length>=6 && check1 && check2 ?
                                             <>
                                             {!changePasswordLoader ?
                                                <div className="manageSaveBtnActive" onClick={() => handleDeleteAccount()}>Confirm</div>
                                                :
                                                <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Confirm...</div>
                                            }
                                                </>
                                                
                                                :
                                        <div className="manageSaveBtn">Confirm</div>
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </ClickAwayListener>
                </Dialog>
            }
</>
:
<>
{!changeDialog ?

<Dialog
                    open={openManageAccountDialog}
                    onClose={() => { setOpenManageAccountDialog(false) }}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
{/* <SwipeableDrawer
anchor="bottom"
open={openManageAccountDialog}
onClose={()=>setOpenManageAccountDialog(false)}
onOpen={()=>setOpenManageAccountDialog(true)}
className='mainDrawerContainer'
> */}
  <ClickAwayListener onClickAway={()=>{setOpenManageAccountDialog(false)}}>
    <div className='switchDialogMainDiv' style={{background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey}}>
                        <div style={{ textAlign: 'end', width: '100%',padding: '10px 10px 0px 0px' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setOpenManageAccountDialog(false) }} src={CloseMenuListIcon} alt='' />
                        </div>
{/* <div className='drawerIcon'>
                                      <div ></div>
                                    </div>
                                    <div className='drawerHeading'>
                                      <div style={{width:'20%'}}></div>
<div style={{width:'100%',textAlign:'center',fontSize:18.5,fontWeight:'600'}}>
Manage Account
</div>
<div className='drawerHeadingDone' onClick={()=>setOpenManageAccountDialog(false)}>Done</div>
</div> */}
                    <div /* className='switchDialogMainDiv' */ style={{ textAlign: 'center',background:colorSchemes.DrawerBackColor }}>
                        <div className="manageAccountContainer" style={{ marginTop: -24 }}>
                        <h4 style={{color:colorSchemes.FontBlackColor }}>Manage Account</h4>
                            <div style={{ marginTop: 20 }}>
                                <div style={{ fontSize: 16,fontFamily:'HelveticaSemiBold' }}>Password</div>
                                {!showChangePasswordDiv ?
                                    <div style={{ fontSize: 14, marginTop: 4,color:colorSchemes.FontLightBlack, }}>
                                        Already set &nbsp;<span className="makeLink" style={{ fontSize: 13 }} onClick={() => { setShowChangePasswordDiv(true); setShowChangeEmailDiv(false); setErrorMsg(""); clearChangeEmailInput();setShowChangeNameDiv(false); }}>Change Password</span>
                                    </div>
                                    :
                                    <div className="manageAccountInnerContentDiv" style={{ marginTop: 4 }}>
                                        <input className="manageAccountInput"  style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} type='password' placeholder="Current Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        <input className="manageAccountInput" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} type='password' placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 12, marginTop: -5 }}>{errorMsg}</div>
                                        }
                                        <div className="manageBtnContainer">
                                            <div className="manageCancleBtn" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}}  onClick={() => { setShowChangePasswordDiv(false);clearChangeEmailInput();clearChangePasswordInput() }}>Cancle</div>
                                            {currentPassword && currentPassword != "" && newPassword && newPassword != "" ?
                                                <>
                                                    {!changePasswordLoader ?
                                                        <div className="manageSaveBtnActive" onClick={() => handleChangePasswordSubmit()}>Save</div>
                                                        :
                                                        <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Save...</div>
                                                    }
                                                </>
                                                :
                                                <div className="manageSaveBtn">Save</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>


                            <div style={{ marginTop: 20 }}>
                                <div style={{ fontSize: 16,fontFamily:'HelveticaSemiBold' }}>Name</div>
                                {!showChangeNameDiv ?
                                    <div style={{ fontSize: 14,color:colorSchemes.FontLightBlack, marginTop: 4 }}>
                                        {Base64.decode(userData.firstName) + ' ' + Base64.decode(userData.lastName)} &nbsp;
                                        <span className="makeLink" style={{ fontSize: 13 }} onClick={() => { setShowChangeNameDiv(true); setShowChangePasswordDiv(false);setShowChangeEmailDiv(false); setErrorMsg(""); clearChangePasswordInput();setNewName(Base64.decode(userData.firstName) + ' ' + Base64.decode(userData.lastName)) }}>Change Name</span>
                                    </div>
                                    :
                                    <div className="manageAccountInnerContentDiv" style={{ marginTop: 4 }}>
                                        <input className="manageAccountInput" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} type='text' placeholder="New Name" value={newName} onChange={(e) => setNewName(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        
                                        {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 12, marginTop: -5 }}>{errorMsg}</div>
                                        }
                                        <div className="manageBtnContainer">
                                            <div className="manageCancleBtn" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} onClick={() => { setShowChangeNameDiv(false);clearChangeEmailInput();clearChangePasswordInput() }}>Cancle</div>
                                            {newName && newName != "" ?
                                             <>
                                             {!changePasswordLoader ?
                                                <div className="manageSaveBtnActive" onClick={() => handleChangeName()}>Ok</div>
                                                :
                                                <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Ok...</div>
                                            }
                                                </>
                                                
                                                :
                                                <div className="manageSaveBtn">Ok</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <div style={{ fontSize: 16,fontFamily:'HelveticaSemiBold' }}>Email</div>
                                {!showChangeEmailDiv ?
                                    <div style={{ fontSize: 14,color:colorSchemes.FontLightBlack, marginTop: 4 }}>
                                        {Base64.decode(userData.email)} &nbsp;
                                        <span className="makeLink" style={{ fontSize: 13 }} onClick={() => { setShowChangeEmailDiv(true); setShowChangePasswordDiv(false); setErrorMsg(""); clearChangePasswordInput();setShowChangeNameDiv(false); }}>Change Email</span>
                                    </div>
                                    :
                                    <div className="manageAccountInnerContentDiv" style={{ marginTop: 4 }}>
                                        <input className="manageAccountInput" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} type='email' placeholder="New Email" value={email} onChange={(e) => setEmail(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        <div style={{ display: 'flex' }}>
                                            <input className="manageAccountInput" style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, width: '100%',background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor }} type='number' placeholder="Verification Code" value={code} onChange={(e) => setCode(e.target.value)} onFocus={() => setErrorMsg("")} />
                                            {email && email != "" ?
                                            <>{!changePasswordLoader ?
                                                <div className="sendCodeBtnActive" onClick={() => handleSendCode()}>Send Code</div>
                                                :
                                                <div className="sendCodeBtnActive" style={{ opacity: 0.65 }}>Send Code...</div>
                                            }
                                                </>
                                                :
                                                <div className="sendCodeBtn">Send Code</div>
                                            }
                                        </div>
                                        <input className="manageAccountInput" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} type='password' placeholder="Current Password" value={changeEmailCurrentPassword} onChange={(e) => setChangeEmailCurrentPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                        {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 12, marginTop: -5 }}>{errorMsg}</div>
                                        }
                                        <div className="manageBtnContainer">
                                            <div className="manageCancleBtn" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} onClick={() => { setShowChangeEmailDiv(false);clearChangeEmailInput();clearChangePasswordInput() }}>Cancle</div>
                                            {email && email != "" && code && code != "" && changeEmailCurrentPassword && changeEmailCurrentPassword != "" ?
                                             <>
                                             {!changePasswordLoader ?
                                                <div className="manageSaveBtnActive" onClick={() => handleChangeEmail()}>Ok</div>
                                                :
                                                <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Ok...</div>
                                            }
                                                </>
                                                
                                                :
                                                <div className="manageSaveBtn">Ok</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <div style={{ fontSize: 16,fontFamily:'HelveticaSemiBold' }}>Manage Account</div>
                                <div style={{ fontSize: 14, color: 'rgba(25,25,25,0.6)', marginTop: 4 }}><span style={{ color: 'red', cursor: 'pointer' }} onClick={() => { setShowDeleteAccountDialog(true); setChangeDialog(true);setShowChangePasswordDiv(false); setShowChangeEmailDiv(false);clearChangeEmailInput();clearChangePasswordInput();setShowChangeNameDiv(false); }}>Delete Account</span></div>
                            </div>

                        </div>
                    </div>
                    </div>
                    </ClickAwayListener>
    {/* </SwipeableDrawer> */}
    </Dialog>
:
<Dialog
                    open={showDeleteAccountDialog}
                    onClose={() => { setShowDeleteAccountDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
{/* <SwipeableDrawer
anchor="bottom"
open={showDeleteAccountDialog}
onClose={() => { setShowDeleteAccountDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}
onOpen={()=>setShowDeleteAccountDialog(true)}
className='mainDrawerContainer'
> */}
<ClickAwayListener onClickAway={()=>{ setShowDeleteAccountDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}>
<div className='switchDialogMainDiv' style={{background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey}}>
<div style={{ textAlign: 'end', width: '100%',padding: '10px 10px 0px 0px' }}>
                            <img style={{ width: 16, cursor: 'pointer' }} onClick={() => { setOpenManageAccountDialog(false) }} src={CloseMenuListIcon} alt='' />
                        </div>
{/* <div className='drawerIcon'>
                                      <div ></div>
                                    </div> */}
                                    {/* <div className='drawerHeading'>
                                      <div style={{width:'20%'}}></div>
<div style={{width:'100%',textAlign:'center',fontSize:18.5,fontWeight:'600'}}>
Delete Account
</div>
<div className='drawerHeadingDone' onClick={() => { setShowDeleteAccountDialog(false); setChangeDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}>Done</div>
</div> */}
<div /* className='switchDialogMainDiv' */ style={{ textAlign: 'center',background:colorSchemes.DrawerBackColor,color:colorSchemes.FontSemiGrey }}>
                        <div className="manageAccountContainer" style={{ marginTop: -24 }}>
                        <h4 style={{color:colorSchemes.FontBlackColor }}>Delete Account</h4>
                            <div style={{ marginTop: 20 }}>
                                <div style={{ color: 'rgba(255,49,128,1)', fontSize: 13, marginBottom: 20 }}>Warning: Deleting account will remove all your data!</div>
                                <input className="manageAccountInput" style={{ width: '100%',background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor }} type='password' placeholder="Please confirm the current password." value={deleteAccountPassword} onChange={(e) => setDeleteAccountPassword(e.target.value)} onFocus={() => setErrorMsg("")} />
                                {errorMsg && errorMsg != "" &&
                                            <div style={{ color: 'rgba(255,49,128,1)', fontSize: 14, }}>{errorMsg}</div>
                                        }
                                <div className="pointCheckBoxDiv"  onClick={() => checkPoints("point1")}>
                                    {check1 ?
                                        <img className='checkIconDiv' src={ManageCheckIcon} alt="" />
                                        :
                                        <div className="unCheckDiv" style={{background:colorSchemes.FontBlackColor}}></div>
                                    }
                                    <div>I am aware that deleting account will remove all my data.</div>
                                </div>
                                <div className="pointCheckBoxDiv" style={{ marginTop: 10 }} onClick={() => checkPoints("point2")}>
                                    {check2 ?
                                        <img className='checkIconDiv' src={ManageCheckIcon} alt="" />
                                        :
                                        <div className="unCheckDiv" style={{background:colorSchemes.FontBlackColor}}></div>
                                    }
                                    <div>I am sure I want to delete my account.</div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="manageBtnContainer" style={{ width: 245, marginTop: 60, marginBottom: 10 }}>
                                        <div className="manageCancleBtn" style={{background:colorSchemes.ManageAccountInputBack,color:colorSchemes.FontBlackColor}} onClick={() => { setShowDeleteAccountDialog(false); setChangeDialog(false);setDeleteAccountPassword("");setCheck1(false);setCheck2(false) }}>Cancle</div>
                                        {deleteAccountPassword && deleteAccountPassword.length>=6 && check1 && check2 ?
                                             <>
                                             {!changePasswordLoader ?
                                                <div className="manageSaveBtnActive" onClick={() => handleDeleteAccount()}>Confirm</div>
                                                :
                                                <div className="manageSaveBtnActive" style={{ opacity: 0.65 }}>Confirm...</div>
                                            }
                                                </>
                                                
                                                :
                                        <div className="manageSaveBtn">Confirm</div>
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </ClickAwayListener>
{/* </SwipeableDrawer> */}
</Dialog>
}
</>
}
        </div>
    );
}

export default ManageAccount;
