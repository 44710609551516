import React, { useEffect, useRef, useState } from 'react';
import '../../module_72776040054_website-header.min.css'
import '../../main.min.css'
import '../../theme-overrides.css'
import './MainPage.css'
import { useNavigate } from "react-router-dom";
import Cookies from '../Cookies/Cookies';
import { COLOR, COLORDARK } from '../Services/constants/Colors';
import { BASE_URL, isProduction, mixPanelTrack, post } from '../Services/Calls';

function MainPage() {

const navigate = useNavigate()

const [mobileMenuShow, setMobileMenuShow ] = useState(false)
const [mobileView, setMobileView]: any = useState<boolean>(
  window.innerWidth <= 768
);

const [isDark, setIsDark]: any = useState(mobileView ? 1 : 0);
const colorSchemes = isDark == 1 ? mobileView ? COLORDARK : COLOR : COLOR;

const [visiterCount, setVisiterCount]: any = useState(0);

useEffect(() => {
  function updateSize() {
    if (window.innerWidth <= 768)
      setMobileView(true);
    else
      setMobileView(false);
  }
  window.addEventListener("resize", updateSize);
}, [mobileView]);

useEffect(() => {
  mixPanelTrack("HomePageView",{"HomePageView":"HomePageView"})
  if(isProduction == 1){
  updatevisitercount()
}
  getvisitercount()
}, []);


async function updatevisitercount() {
  try {
    const response = await post(BASE_URL + '/updatevisitercount',{})
    console.log('response23',response)

  } catch (ex) {
    console.log('response',ex)
};
}

async function getvisitercount() {
  try {
    const response = await post(BASE_URL + '/getvisitercount',{})
    console.log('response23',response)
    if(response && response.data && response.data.data && response.data.data.length>0){
      setVisiterCount(response.data.data[0].counts)
    }
  } catch (ex) {
    console.log('response',ex)
};
}


function openMobileMenu(){
  if(mobileMenuShow){
    setMobileMenuShow(false)
  }
  else{
    setMobileMenuShow(true)
  }
}


  return (
    <div className="body-wrapper   hs-content-id-74985561604 hs-site-page page">
      {localStorage.getItem("setCookie") == null && <Cookies />}
        <div >
          <header className="header" style={{background:colorSchemes.MainBackGroundColor}}>
  {/* <a href="#" className="header__skip">Skip to content</a> */}
  <div className="container-fluid">
<div className="row-fluid-wrapper">
<div className="row-fluid">
<div className="span12 widget-span widget-type-cell " >
<div className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section header-row-0-padding header-row-0-force-full-width-section">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module" >
<div id="hs_cos_wrapper_header-module-1" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" >
  <div className="header__container header__container--left">  
    <div className="header__bottom" style={{background:colorSchemes.MainBackGroundColor}}>
      <div className="header__wrapper header__wrapper--bottom">
        <div className="header__logo-col">
  <div className="header__logo">
  <div className="header__logo-link" style={{fontSize:mobileView?30:36,fontWeight:'bold',color:colorSchemes.FontBlackColor}}>
   ManageStuff
  </div>
  </div>
        </div>
        <div className="header__menu-col">
            <nav className="header__menu header__menu--desktop" aria-label="Desktop navigation">
    <ul className="header__menu-wrapper no-list">
          
    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite customCursor">
      <a className="header__menu-link  header__menu-link--active-link customCursor" aria-current="page">Features</a>
    </li>

    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite customCursor">
      <a className="header__menu-link  header__menu-link--active-link customCursor" aria-current="page">Premium</a>
    </li>

    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite customCursor">
      <a className="header__menu-link  header__menu-link--active-link customCursor" aria-current="page">Help Center</a>
    </li>

    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite" onClick={() => navigate("/login")}>
      <a className="header__menu-link  header__menu-link--active-link " aria-current="page">Login</a>
    </li>
  
    </ul>
  </nav>


          

  <div className="header__menu-container header__menu-container--mobile">
    {!mobileMenuShow ?
    <button className="header__menu-toggle header__menu-toggle--open header__menu-toggle--show no-button" onClick={()=>openMobileMenu()}>
      <span className="show-for-sr">Open main navigation</span>
      <svg fill='#fff' version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" aria-hidden="true"><g id="bars1_layer"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></g></svg>
    </button>
    :
    <button className="header__menu-toggle header__menu-toggle--close no-button header__menu-toggle--show" onClick={()=>openMobileMenu()}>
      <span className="show-for-sr">Close main navigation</span>
      <svg fill='#fff' version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="24" aria-hidden="true"><g id="times2_layer"><path d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z"></path></g></svg>
    </button>
    }

{mobileMenuShow &&
    <nav className="header__menu header__menu--mobile header__menu--show" aria-label="Mobile navigation" style={{top:'82%',background:colorSchemes.DrawerBackColor}}>
      <ul className="header__menu-wrapper no-list">
        

  

  

  
    
      <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite customCursor" >
      <a className="header__menu-link  header__menu-link--active-link customCursor" aria-current="page" style={{color:colorSchemes.FontBlackColor}}>Features</a>
    </li>

    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite customCursor">
      <a className="header__menu-link  header__menu-link--active-link customCursor" aria-current="page" style={{color:colorSchemes.FontBlackColor}}>Premium</a>
    </li>

    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite customCursor">
      <a className="header__menu-link  header__menu-link--active-link customCursor" aria-current="page" style={{color:colorSchemes.FontBlackColor}}>Help Center</a>
    </li>

    <li className="header__menu-item header__menu-item--depth-1  hs-skip-lang-url-rewrite" onClick={() => navigate("/login")}>
      <a className="header__menu-link  header__menu-link--active-link " aria-current="page" style={{color:colorSchemes.FontBlackColor}}>Login</a>
    </li>
  
  


        
        
          <li className="header__menu-item header__menu-item--button header__menu-item--depth-1 hs-skip-lang-url-rewrite">

  

          <a className="header__button button button--secondary  " rel="noopener" style={{color:colorSchemes.FontBlackColor}} onClick={() => navigate("/signup")}>

    
  Sign Up for Free

  </a>

</li>
        
      </ul>
    </nav>
}
  </div>


        </div>
        
          <div className="header__button-col hidden-phone">
            

  

  
    
  

  

  

  
  
  
  
  
    
  

  <a className="header__button button button--secondary  " rel="noopener" onClick={() => navigate("/signup")}>

    
 Sign Up for Free

  </a>


          </div>
        
      </div>
    </div>

  </div>



</div>

</div>
</div>
</div>

</div>
</div>
</div>
</div>

</header></div>
      

      
      
        
  <div className="container-fluid" style={{padding:mobileView?0:""}}>
<div className="row-fluid-wrapper">
<div className="row-fluid">
<div className="span12 widget-span widget-type-cell " >

{/* {visiterCount &&
<div style={{border:"0.1px solid lightgray",position: 'absolute',right: '16.5%',padding: '10px',borderRadius: '8px',textAlign:'center',fontWeight:600,color:colorSchemes.FontBlackColor}}>
  Visiters <br /> {visiterCount}
</div>
} */}

<div className="row-fluid-wrapper row-depth-1 row-number-1 dnd_area-row-0-max-width-section-centering dnd-section dnd_area-row-0-background-gradient dnd_area-row-0-padding" style={mobileView?{height:"calc(100vh - 85px)",background:colorSchemes.MainBackGroundColor}:{}}>
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd-column" >

<div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module">
<div id="hs_cos_wrapper_dnd_area-module-2" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"><span id="hs_cos_wrapper_dnd_area-module-2_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
  {/* <div style={{textAlign: "center", textTransform: "uppercase", letterSpacing: "3px"}}><span style={{color: "#000000"}}><strong>Home Page</strong></span></div> */}
<h1 style={{textAlign: "center",fontSize:mobileView?36:62,marginBottom:mobileView?80:100,fontFamily: 'DM Serif Display, serif'}}><span style={{color: colorSchemes.FontBlackColor,fontWeight:600}}>A Simple To Do list for Small bussiness, teams and families</span></h1>
{/* <div style={{textAlign: "center", fontSize: "20px", lineHeight: "1.5"}}>
<p style={{fontSize: "20px", textAlign: "center", lineHeight: 1.25}}><span style={{color: "#33302f"}}>This is the free demo version of the Generator website theme for HubSpot. </span></p>
<p style={{fontSize: "20px", textAlign: "center", lineHeight: 1.25}}><span style={{color: "#33302f"}}>Click the button below for the full version.</span></p>
</div> */}
</span></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module" >
<div id="hs_cos_wrapper_dnd_area-module-3" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module">


<div className="button-wrapper">
  <a className="button button--brutalist button--large " rel="noopener" onClick={() => navigate("/signup")} style={{color:colorSchemes.FontBlackColor,background:colorSchemes.DrawerBackColor}}>
    
   Get Started
  </a>
</div></div>

</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
     
    
  );
}

export default MainPage;
